import React, { useState, useEffect, useRef } from 'react';
import { Source, Layer } from 'react-map-gl';

const RadioLine = ({ lineCoordinates, lineColor, line_id}) => {
  const [lineOpacity, setLineOpacity] = useState(0.3);
  const [lineWidth, setLineWidth] = useState(5);
  const animationFrameRef = useRef(null);
  const frameCountRef = useRef(0);

  const animateLine = () => {
    const frame = frameCountRef.current;

    if (frame % 100 === 0) {
      setLineOpacity(0.3);
      setLineWidth(5); 
    }
    
    setLineOpacity((prevOpacity) => {
      if (prevOpacity >= 0.8)
        return 0.3;
      else
        return prevOpacity + 0.01; 
    });

    setLineWidth((prevWidth) => {
      if (prevWidth >= 10)
        return 5;
      else {
        return prevWidth + 0.1;
      }
    });

    frameCountRef.current += 1;
    animationFrameRef.current = requestAnimationFrame(animateLine);
  };

  useEffect(() => {
    animationFrameRef.current = requestAnimationFrame(animateLine);
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  const lineData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: lineCoordinates,
        },
      },
    ],
  };

  const source_id = "static-cpm-line"+line_id;
  const line_adj_id = "solid-layer-line"+line_id;

  return (
    <Source id={source_id} type="geojson" data={lineData}>
      <Layer
        id={line_adj_id}
        type="line"
        paint={{
          'line-color': lineColor,
          'line-width': lineWidth,
          'line-opacity': lineOpacity,
        }}
      />
    </Source>
  );
};

export default RadioLine;
